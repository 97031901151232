import { ContextActions } from '../../map-context';

export class LocationContext {
  constructor() {
    this.location = null;
    this.onHeaderAction = this.onHeaderAction.bind(this);
    this.onBarContextActions = this.onBarContextActions.bind(this);
  }
  
  activate(model) {
    if (model) {
      this.location = model.item;
      this.contextAction = model.contextAction;
    }
  }

  onHeaderAction() {
    this.contextAction(ContextActions.poi, { address: this.location })
  }

  onBarContextActions(e, action) {
    this.contextAction(ContextActions[action],  { latlng: this.location.latlng });
  }
}
