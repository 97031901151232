import {
  bindable,
  observable,
  computedFrom
} from 'aurelia-framework';

import './map-lists.scss';

export class MapLists {

  @bindable searchFilter;
  @bindable previewItem;
  @bindable context;

  @bindable onAssetSelected;
  @bindable onAssetGroupSelected;

  @bindable onPoiSelected;
  @bindable onPoiGroupSelected;
  @bindable onPoiAddressSelected;
  @bindable opened;

  @bindable onListAction;
  @bindable onContextClear;

  @observable searchAddresses;

  constructor() {
    this.context = {};
    this.subContext = {};
    this.mapFiltering = false;
    this.searchAddresses = false;
    this.showSearchAddressesAction = false;
    this.tempSearchFilter = '';
    this.allowCloseOnEsc = true;

    this.onAssetListSelected = this.onAssetListSelected.bind(this);
    this.onAssetListGroupSelected = this.onAssetListGroupSelected.bind(this);
    this.onPOIListSelected = this.onPOIListSelected.bind(this);
    this.onPOIListGroupSelected = this.onPOIListGroupSelected.bind(this);
    this.onPOIAddressSelected = this.onPOIAddressSelected.bind(this);
    this.onReturn = this.onReturn.bind(this);
    this.toggleMapFilter = this.toggleMapFilter.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  attached() {
    window.addEventListener('keydown', this.onKeyDown);
    this.setSubContext('search', '');
  }

  detached() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(e) {
    if (this.showSearchAddressesAction && this.showPOIList && e.keyCode == 13) {
      this.toggleSearchAddresses();
    } 

    if (!this.allowCloseOnEsc || e.keyCode !== 27) return;
    
    if (this.subContext && this.subContext.type == 'group') {
      this.setSubContext('search', '');
      return;
    }
    
    if (this.opened) {
      this.onReturn()
    }
  }
  
  cleanSearch() {
    this.setSubContext('search', '');
  }

  searchFilterChanged() {
    if (this.tempSearchFilter && this.searchFilter != this.tempSearchFilter) {
      this.toggleMapFilter(null, false);
    }
  }

  @computedFrom('context')
  get showAssetList() {
    return this.context && this.context.type === 'asset';
  }

  get showPOIList() {
    return this.context && this.context.type === 'poi';
  }

  onAssetListSelected(asset, action) {
    if (this.onAssetSelected) {
      this.onAssetSelected(asset, action);
    }
  }

  onAssetListGroupSelected(group) {
    if (group) {
      this.setSubContext('group', group);
    }
    if (this.onAssetGroupSelected) {
      this.onAssetGroupSelected(group);
    }
  }

  onPOIListGroupSelected(group) {
    if (group) {
      this.setSubContext('group', group);
    }
    if (this.onPoiGroupSelected) {
      this.onPoiGroupSelected(group);
    }
  }

  onPOIListSelected(poi) {
    if (this.onPoiSelected) {
      this.onPoiSelected(poi);
    }
  }

  onPOIAddressSelected(address) {
    if (this.onPoiAddressSelected) {
      this.onPoiAddressSelected(address)
    }
  }

  onReturn(e) {
    this.setSubContext('search', '');
    this.clearFilter();

    if (this.onContextClear) {
      this.onContextClear()
    }
  }

  clearFilter() {
    this.searchFilter = "";
  }

  setSubContext(subContextType, subContext) {
    switch (subContextType) {
      case 'group':
        this.searchFilter = subContext.name
        this.subContext.title = subContext.name;
        break;
      case 'search':
        this.searchFilter = subContext;
        break;
    }
    if (this.subContext.type == 'group' && subContextType == 'search') {
      if (this.context.type === 'asset') {
        this.onAssetListGroupSelected(null);
      } else if (this.context.type === 'poi') {
        this.onPOIListGroupSelected(null);
      }
    }

    this.subContext.type = subContextType;
  }

  @computedFrom('searchFilter')
  get showMapFilter() {
    return (!this.searchFilter || this.searchFilter.length < 1) ? false : true;
  }

  toggleMapFilter(e, toggle) {
    this.mapFiltering = (toggle) ? toggle : !this.mapFiltering;
    this.tempSearchFilter = (this.mapFiltering) ? this.searchFilter : '';

    if (this.onListAction) {
      this.onListAction(this.mapFiltering ? 'filter' : 'unfilter', this.context);
    }
  }

  toggleSearchAddresses(e) {
    this.searchAddresses = !this.searchAddresses;
    if (this.searchAddresses && this.mapFiltering) {
      this.toggleMapFilter(null, false);
    }
  }
}
