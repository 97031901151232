/** @format */

import {
  customElement,
  inject,
  bindable,
  observable,
  computedFrom
} from 'aurelia-framework';
import {
  HttpClient
} from 'aurelia-fetch-client';

import uiService from 'services/uiService';
import LOG from 'services/loggerServices';
import eventService, {
  EventsList
} from 'services/eventService';

import './map-context.scss';

import * as Actions from 'components/cctv/cctv-store-manager/actions.js';
import { Store } from 'aurelia-store';


export const ContextActions = {
  trail: 'trail',
  trip: 'trip',
  focus: 'focus',
  poi: 'poi',
  draw: 'draw',
  close: 'close',
  edit: 'edit',
  nearby: 'nearby',
  nearbyRoute: 'nearbyRoute',
  eta: 'eta',
  alert: 'alert',
  reminder: 'reminder'
};

@inject(HttpClient, Store)
@customElement('map-context')
export class MapContext {
  @bindable contextItem;
  @bindable onContextAction;
  @bindable onContextClear;

  constructor(_HttpClient, _store) {
    this.store = _store;
    this.actions = Actions;

    this._httpClient = _HttpClient;
    //
    this.uiService = uiService;
    //nearby context
    //for now it's here as it's child-context that is common to all contexts
    this.openNearby = false;
    this.openETA = false;
    this.contextChild = null;
    this.allowCloseOnEsc = true;

    this.nearbyLocation = null;
    this.onCloseNearby = this.onCloseNearby.bind(this);
    this.onCloseETA = this.onCloseETA.bind(this);
    this.onReturn = this.onReturn.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

    this.onNearbyAssetSelected = this.onNearbyAssetSelected.bind(this);
  }

  attached() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  detached() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(e) {
    if (!this.allowCloseOnEsc || e.keyCode !== 27) return;
    if (this.contextChild || this.openETA || this.openNearby) return;

    this.onReturn();
  }

  @computedFrom('contextItem')
  get contextLatLng() {
    if (this.contextItem && this.contextItem.latlng) {
      return this.contextItem.latlng;
    }
    return [];
  }

  get contextTitle() {
    return this.contextItem ? this.contextItem.formattedName || this.contextItem.name : null;
  }

  get contextType() {
    return this.contextItem ? this.contextItem._name : null;
  }

  get contextIcon() {
    switch (this.contextType) {
      case 'asset':
        return 'fi-car';
      case 'poi':
        return 'fi-map-marker';
      case 'location':
        return 'fi-pinned_location';
    }
  }

  contextItemChanged(newItem, oldItem) {
    if (newItem !== oldItem) {
      this.setState(newItem);
    }
  }

  setState(item, type) {
    this.openETA = false;
    this.openNearby = false;
    this.nearbyLocation = null;

    this.contextItem = item;
    this.contextModel = {
      item: item,
      contextAction: this.actionClick.bind(this)
    };
  }

  ////Some way to getting action from specific context vm's action up the component chain
  //I'm not really happy with how this is done, Maybe it's better just to use events...
  //It's confusing the way the events are bubbled up and down the context chain
  actionClick(action, args) {

    switch (ContextActions[action]) {
      case ContextActions.nearby:
        this.nearbyLocation = args.latlng;
        this.openNearby = true;
        return Promise.resolve(true);
      case ContextActions.eta:
        this.openETA = true;
        return Promise.resolve(true);
      case ContextActions.trip:
      case ContextActions.alert:
      case ContextActions.reminder:
        this.contextChild = args;
        break;
      case ContextActions.focus:
        this.contextChild = null;
        break;
    }

    return this.triggerContextAction(action, args || this.contextItem);
  }

  //
  onCloseNearby() {
    this.openNearby = false;
    this.triggerContextAction(ContextActions.nearbyRoute);
    this.triggerContextAction(ContextActions.focus, {
      asset: this.contextItem,
      skipLoad: true
    });
  }

  onCloseETA(eta) {
    this.openETA = false;
    this.triggerContextAction(ContextActions.eta, {
      eta: eta
    });
    this.contextItem.eta = eta;
  }

  onNearbyAssetSelected(asset) {
    if (!asset) return;

    //Focus on asset in case there is a trail currently showing
    let args = {
      asset: this.contextItem
    };
    this.triggerContextAction(ContextActions.focus, args).then(() => {
      this.triggerContextAction(ContextActions.nearbyRoute, {
        route: asset.route
      });

      eventService.publish(EventsList.MapContextNearby);
    });
  }

  triggerContextAction(action, args) {
    if (!(typeof this.onContextAction === 'function')) {
      LOG.warn('Invalid action from map context!');
      return Promise.resolve(false);
    }

    return this.onContextAction(action, args);
  }

  onReturn() {
    if (this.onContextClear) {
      
      if(this.store.state.loading || this.store.state.playing) {
        this.actions.liveStreamDetached(true);
      }
      setTimeout(() => {
        this.onContextClear()
      }, 0)
    }
  }
}
