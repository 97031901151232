import {
  bindable,
  computedFrom
} from 'aurelia-framework';

import './map-lists-actions.scss';

export class MapListsActions {

  @bindable searchFilter;
  @bindable previewItem;
  @bindable contextList;

  @bindable onAssetSelected;
  @bindable onPoiSelected;
  @bindable onPoiAddressSelected;
  @bindable closed;

  @bindable onListAction;

  constructor() {
    this.searchFilter = '';
    this.onListToggle = this.onListToggle.bind(this);
  }

  onListToggle(e, list) {
    if (list && list.type && this.onListAction) {
      this.onListAction('openList', list);
    }
  }

}
