/** @format */

import {
  bindable
} from 'aurelia-framework';
import alertsService from 'services/api/alertsService';

import './asset-alerts.scss';

export class AssetAlerts {
  @bindable asset;
  @bindable dateRange;

  @bindable onAlertClick;

  constructor() {
    this.alertsService = alertsService;
    this.alerts = [];
    this.alertsCount = 0;
    this.showMax = 4;

    this.selectAlert = this.selectAlert.bind(this);
  }

  dateRangeChanged() {
    this.loadData();
  }

  assetChanged(asset, old) {
    if (asset && old && asset.id === old.id) return;

    this.loadData();
  }

  loadData() {
    if (this.asset && this.dateRange) {
      let {
        dtto,
        dtfrom
      } = this.dateRange || {};
      this.alertsService
        .getAssetOccurrences(this.asset.id, dtfrom, dtto)
        .then(alerts => {
          this.alertsCount = alerts.length;
          this.alerts = alerts.slice(0, this.showMax);
        });
    }
  }

  selectAlert(alert) {
    if (this.onAlertClick) {
      this.onAlertClick(alert, this.alerts);
    }
  }
}
