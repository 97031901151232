/** @format */

import {
  bindable,
  computedFrom
} from 'aurelia-framework';

import assetsService from 'services/api/assetsService';
import geoService from 'services/api/geoService';

import './eta-context.scss';

export class EtaContext {
  @bindable assetId;
  @bindable eta;

  @bindable open;
  @bindable onClose;

  constructor() {
    this.assetsService = assetsService;
    //
    this.geoService = geoService;
    //
    this.etaPreview = null;

    this.loading = false;
    this.searchItems = [];
    this.allowCloseOnEsc = true;

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onItemSelect = this.onItemSelect.bind(this);
    this._onClose = this._onClose.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  attached() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  detached() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(e) {
    if (!this.allowCloseOnEsc || e.keyCode !== 27) return;

    this._onClose();
  }

  _onClose() {
    this.etaPreview = this.eta;
    this.searchValue = null;
    this.close();
  }

  @computedFrom('eta')
  get hasETA() {
    return !!this.eta;
  }

  etaChanged(value) {
    this.etaPreview = value || null;
    this.searchValue = null;
  }

  onSearchChange(value) {
    if (this.skipSearch) {
      this.skipSearch = false;
      return;
    }
    this.search(value);
  }

  search(value) {
    if (!value || value.length < 3) {
      this.searchItems = [];
      return;
    }

    this.loading = true;
    this.geoService.globalSearch(value).then(results => {
      this.searchItems = results.map(r => {
        r.icon = r.type === 'poi' ? 'map-marker' : 'pinned_location';
        return r;
      });
      this.loading = false;
    });
  }

  onItemSelect(item) {
    if (!item) {
      this.searchValue = null;
      this.etaPreview = null;
      this.searchItems = [];
      return;
    }
    this.loading = true;
    this.searchValue = item.name;
    this.searchItems = null;
    this.skipSearch = true;

    this.loadItemLocation(item)
      .then(loc => {
        return this.loadETA(this.assetId, loc);
      })
      .then(eta => {
        this.etaPreview = eta;
        this.loading = false;
      })
      .catch(e => {
        this.loading = false;
        throw new Error(e);
      });
  }

  loadItemLocation(item) {
    if (item) {
      let props = item.properties;
      if (props.location) {
        return Promise.resolve(props.location);
      }

      return this.geoService
        .searchPlace(item.id, props.provider)
        .then(
          place =>
          place && place.properties ? place.properties.location : null
        );
    }
    return Promise.reject(null);
  }

  loadETA(assetId, location) {
    if (!location || !assetId) {
      return Promise.reject(false);
    }

    const {
      latitude,
      longitude
    } = location;
    return this.assetsService.updateETA(
      assetId,
      latitude,
      longitude,
      this.searchValue,
      true
    );
  }

  onToggleETAClick() {
    if (this.hasETA) {
      return this.stopETA();
    }

    this.startETA();
  }

  stopETA() {
    this.loading = true;
    this.assetsService.deleteETA(this.assetId).then(() => {
      this.loading = false;
      this.eta = null;
      this.etaPreview = null;
    });
  }

  startETA() {
    this.loading = true;
    let {
      latitude,
      longitude
    } = this.etaPreview.location;
    this.assetsService
      .updateETA(this.assetId, latitude, longitude, this.searchValue)
      .then(eta => {
        this.loading = false;
        this.close(eta);
        this.eta = eta;
      });
  }

  close(eta) {
    if (this.onClose) {
      this.onClose(eta);
    }
  }
}
