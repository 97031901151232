/** @format */
import {
  computedFrom
} from 'aurelia-framework';
import alertsService from 'services/api/alertsService';
import {
  ContextActions
} from '../../../map-context';

import './alert-context-details.scss';

export class AlertContextDetails {
  constructor() {
    this.alertsService = alertsService;
    this.loading = false;
    this.contextAction = null;
    this.contextHeaderViews = {
      streetView: 'streetView',
      cctv: 'cctv',
    }
    this.activeView = this.contextHeaderViews.streetView;
    this.hasCCTV = false;
    this.cctvChannels = [];
    this.selectedChannel = {};
  }

  activate(model) {
    if (!model) return;
    this.alert = model.alert;
    this.alerts = model.alerts;
    this.contextAction = model.contextAction;
    this.hasCCTV = model.hasCCTV
  }

  onProcessClick() {
    this.loading = true;
    this.update({ ...this.alert,
      processed: true
    }).then(alert => {
      this.alert = alert;
      this.loading = false;
    });
  }

  onConfirm() {
    this.update(this.alert).then(alert => {
      //server not returning comments array
      this.alert.comments.push(this.alert.comment);
      this.alert.comment = null;
    });
  }

  update(alert) {
    return this.alertsService.updateOccurrence(alert);
  }

  @computedFrom('alert')
  get latlng() {
    if (!this.alert || !this.alert.startAddress) return false;
    return [this.alert.startAddress.location.latitude, this.alert.startAddress.location.longitude];
  }

  @computedFrom('alerts')
  get alertsLength() {
    if (!this.alerts) return false;
    return this.alerts.length;
  }

  @computedFrom('alerts')
  get checkPreviousAlertState() {
    if (!this.alerts) return false;

    let currentAlertIndex = this.alerts.findIndex(alert => alert.id === this.alert.id)
    return (currentAlertIndex > 0 && this.alerts[currentAlertIndex - 1]) ? true : false;
  }

  @computedFrom('alerts')
  get checkNextAlertState() {
    if (!this.alerts) return false;

    let currentAlertIndex = this.alerts.findIndex(alert => alert.id === this.alert.id)
    return (currentAlertIndex >= 0 && this.alerts[currentAlertIndex + 1]) ? true : false;
  }

  previousAlert() {
    let currentAlertIndex = this.alerts.findIndex(alert => alert.id === this.alert.id)
    let previousAlertIndex = (currentAlertIndex > 0 && this.alerts[currentAlertIndex - 1]) ? currentAlertIndex - 1 : 0;
    let alert = this.alerts[previousAlertIndex];

    if (alert && currentAlertIndex != previousAlertIndex) {
      this.changeAlert(alert)
    }
  }

  nextAlert() {
    let currentAlertIndex = this.alerts.findIndex(alert => alert.id === this.alert.id)
    let nextAlertIndex = (currentAlertIndex >= 0 && this.alerts[currentAlertIndex + 1]) ? currentAlertIndex + 1 : currentAlertIndex;
    let alert = this.alerts[nextAlertIndex]

    if (alert && currentAlertIndex != nextAlertIndex) {
      this.changeAlert(alert)
    }
  }

  changeAlert(alert) {
    this.loading = true;
    this.contextAction(ContextActions.alert, {
      alert
    }).then(result => {
      this.loading = false;
      this.alert = alert;
    });
  }
}
