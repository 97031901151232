/** @format */

import {
  inject,
  computedFrom,
  NewInstance
} from 'aurelia-framework';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';

import poiService, {
  POI
} from 'services/api/areasService';

import {
  ContextActions
} from '../../map-context';
import eventService, {
  EventsList
} from 'services/eventService';

import './poi-context.scss';

@inject(NewInstance.of(ValidationController))
export class PoiContext {
  constructor(_ValidationController) {
    this.poiService = poiService;
    this.validationController = _ValidationController;

    this.poi = null;
    this.loading = false;
    this.errorMessage = null;

    this.rules = ValidationRules.ensure(p => p.name)
      .required().minLength(2).withMessageKey('tooShort')
      .ensure(p => p.geometry)
      .required().rules;

    this.onHeaderAction = this.onHeaderAction.bind(this);
    this.onMapServiceDrawEnd = this.onMapServiceDrawEnd.bind(this);
    this.onMapServiceShapes = this.onMapServiceShapes.bind(this);
    this.onBarContextActions = this.onBarContextActions.bind(this);
  }

  @computedFrom('poi', 'poi.id', 'poi.edit')
  get isEditing() {
    return !this.poi.id || this.poi.edit;
  }

  @computedFrom('isEditing')
  get headerIcon() {
    return this.isEditing ? 'fi-save' : 'fi-edit';
  }

  @computedFrom('poi.geometry', 'poi.name', 'poi.formattedAddress')
  get canSave() {
    return this.poi.geometry && this.poi.name;
  }

  @computedFrom('isEditing', 'canSave')
  get headerActionEnabled() {
    return this.isEditing ? this.canSave : true;
  }

  @computedFrom('poi', 'isEditing')
  get nearbyLatLng() {
    return !this.isEditing ? this.poi.latlng : null;
  }

  activate(model) {
    if (model) {
      this.poi = model.item;
      this.contextAction = model.contextAction;
    }
  }

  attached() {
    eventService.subscribe(
      EventsList.MapServiceDrawEnd,
      this.onMapServiceDrawEnd
    );
    eventService.subscribe(
      EventsList.MapServiceShapes,
      this.onMapServiceShapes
    );
  }

  detached() {
    eventService.unsubscribe(
      EventsList.MapServiceDrawEnd,
      this.onMapServiceDrawEnd
    );
    eventService.unsubscribe(
      EventsList.MapServiceShapes,
      this.onMapServiceShapes
    );
  }

  onHeaderAction() {
    if (this.isEditing) {
      this.onSave();
    } else {
      let editPOI = new POI(this.poi);
      editPOI.edit = true;
      this.contextAction(ContextActions.edit, {
        item: editPOI,
        editableShapes: true
      });
    }
  }

  onMapServiceDrawEnd = shape => {
    this.poi.geometry = shape;
    this.activeShape = null;
  };

  onShapeClick(shape) {
    this.activeShape = shape;
    this.poi.geometry = null;
    this.contextAction(ContextActions.draw, {
      shape: shape
    });
  }

  onSave() {
    eventService.publish(EventsList.MapServiceRequestShapes, false);
  }

  onMapServiceShapes = shapes => {
    this.poi.geometry = shapes[0];
    this.save();
  };

  onBarContextActions(e, action) {
    this.contextAction(ContextActions[action], {
      latlng: this.poi.latlng
    });
  }

  save() {
    this.validate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      return this.poiService
        .update(this.poi)
        .then(poi => {
          this.loading = false;
          poi.edit = false;

          eventService.publish(EventsList.POIChanged, poi);
          this.contextAction(ContextActions.close, {
            item: poi
          });
        }).catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  validate() {
    return this.validationController
      .validate({
        object: this.poi,
        rules: this.rules
      })
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }
}
