/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import {
  ConvertUtils
} from '@fonix/web-utils';
import driversService from 'services/api/driversService';

import eventService, {
  EventsList
} from 'services/eventService';
import {
  ContextActions
} from '../../../map-context';

import './trip-context-details.scss';

export class TripContextDetails {
  constructor() {
    this.driversService = driversService;

    this.chartOptions = {
      fullWidth: true,
      height: 200,
      axisY: {
        offset: 40,
        labelInterpolationFnc: v => {
          return v;
        }
      },
      axisX: {
        // offset: 0,
        postion: 'start',
        labelOffset: {
          x: -10,
          y: 0
        },
        showGrid: false,
        labelInterpolationFnc: (v, idx, arr) => {
          return idx < 1 || idx >= arr.length - 1 ? v : null;
        }
      },
      // showPoint: false,
      chartPadding: {
        right: 25
      }
    };

    this.onChartPointClick = this.onChartPointClick.bind(this);
    this.chartData = null;
    this.driverName = null;
    this.loading = false;
    this.contextAction = null;
  }

  attached() {
    this.distanceUnit = (ConvertUtils.getUnit('distance') || {}).name;
  }

  @computedFrom('model')
  get trip() {
    if (this.model) {
      return this.model.trip;
    }
  }

  @computedFrom('model')
  get tripsLength() {
    if (!this.model || !this.model.trips) return false;
  
    return this.model.trips.length;
  }

  @computedFrom('model')
  get checkPreviousJourneyState() {
    if (!this.model || !this.model.trips) return false;
    
    let currentTripIndex = this.model.trips.findIndex(trip => trip === this.model.trip)
    return (currentTripIndex > 0 && this.model.trips[currentTripIndex - 1]) ? true : false;
  }

  @computedFrom('model')
  get checkNextJourneyState() {
    if (!this.model || !this.model.trips) return false;

    let currentTripIndex = this.model.trips.findIndex(trip => trip === this.model.trip)
    return (currentTripIndex >= 0 && this.model.trips[currentTripIndex + 1]) ? true : false;
  }

  @computedFrom('model')
  get telemetry() {
    if (this.model) {
      return this.model.telemetry;
    }
  }

  activate(model) {
    if (!model) return;
    this.loading = true;
    this.model = model;
    this.contextAction = model.contextAction;

    this.loading = false;
    this.loadDriver();
    this.loadChartData();
  }

  previousTrip() {
    let currentTripIndex = this.model.trips.findIndex(trip => trip === this.model.trip)
    let previousTripIndex = (currentTripIndex > 0 && this.model.trips[currentTripIndex - 1]) ? currentTripIndex - 1 : 0;
    let trip = this.model.trips[previousTripIndex];

    if (trip && currentTripIndex != previousTripIndex) {
      this.changeTrip(trip)
    }
  }

  nextTrip() {
    let currentTripIndex = this.model.trips.findIndex(trip => trip === this.model.trip)
    let nextTripIndex = (currentTripIndex >= 0 && this.model.trips[currentTripIndex + 1]) ? currentTripIndex + 1 : currentTripIndex;
    let trip = this.model.trips[nextTripIndex]

    if (trip && currentTripIndex != nextTripIndex) {
      this.changeTrip(trip)
    }
  }

  changeTrip(trip) {
    this.loading = true;
    let args = {
      dtfrom: trip.gpsStartLocal,
      dtto: trip.gpsEndLocal,
      id: trip.assetId
    };
    this.contextAction(ContextActions.trip, args).then(telemetry => {
      this.model.telemetry = telemetry;
      this.model.trip = trip;

      this.loading = false;
      this.loadDriver();
      this.loadChartData();
    });
  }

  onChartPointClick(e) {
    let target = e.target;
    if (target) {
      let elem = target.parentElement;
      let latlng = elem.getAttribute('ct:meta');
      if (latlng) {
        latlng = latlng.split('|');
        if (latlng.length > 1) {
          eventService.publish(EventsList.MapContextTripPoint, [
            +latlng[0],
            +latlng[1]
          ]);
        }
      }
    }
  }

  loadChartData() {
    if (this.telemetry) {
      let speeds = this.telemetry.map(t => {
        return {
          value: ConvertUtils.convertUnit('speed', t.speed, false).value,
          meta: `${t.latitude}|${t.longitude}`,
          className: t.speedLevel
        };
      });
      let labels = this.telemetry.map(t => {
        return t.gpsHour;
      });

      let series = [];
      let currSeries = [];
      for (let k = 0; k < labels.length - 1; k++) {
        currSeries = [];
        for (let i = 0; i < labels.length - 1; i++) {
          if (i === k) {
            currSeries.push(speeds[k]);
            currSeries.push(speeds[k + 1]);
          } else {
            currSeries.push(null);
          }
        }
        series.push({
          value: currSeries,
          className: speeds[k].className,
          meta: speeds[k].meta
        });
      }
      this.chartData = {
        labels,
        series
      };
    }
  }

  loadDriver() {
    this.driverName = null;

    let id = (this.trip && this.trip.driverId) || null;
    if (!id) return;

    this.driversService.get(id).then(driver => {
      this.driverName = driver ? driver.name : null;
    });
  }
}
