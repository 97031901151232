/** @format */

import {
  bindable
} from 'aurelia-framework';
import assetsService from 'services/api/assetsService';

import './nearby-context.scss';

export class NearbyContext {
  @bindable open;
  @bindable onClose;
  @bindable contextItem;
  @bindable location;
  @bindable onAssetSelected;

  constructor() {
    this.assetsService = assetsService;

    this.location = null;
    this.loading = false;
    this.nearbyAssets = [];
    this.allowCloseOnEsc = true;

    this.onAssetClick = this.onAssetClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  attached() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  detached() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(e) {
    if (!this.allowCloseOnEsc || e.keyCode !== 27) return;

    if (this.onClose) {
      this.onClose();
    }
  }

  locationChanged(location) {
    this.nearbyAssets = [];
    if (location) {
      this.searchNearby(location);
    }
  }

  searchNearby(location) {
    this.loading = !!location;
    if (location) {
      let [latitude, longitude] = location;
      this.assetsService.findNearest(latitude, longitude).then(assets => {
        this.nearbyAssets = assets;
        this.loading = false;
      });
    }
  }

  onAssetClick(asset) {
    if (this.onAssetSelected && asset) {
      this.nearbyAssets.map(a => (a.selected = a.id === asset.id));
      this.onAssetSelected(asset);
    }
  }
}
