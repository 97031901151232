/** @format */

import {
  computedFrom,
  bindable
} from 'aurelia-framework';
import {
  DialogService
} from 'aurelia-dialog';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';

import {
  ContextActions
} from '../../map-context';

import assetsService from 'services/api/assetsService';
import userService from 'services/api/userService';

import uiService from 'services/uiService';
import eventService, {
  EventsList
} from 'services/eventService';
import provisionService from 'services/api/provisionService';

import './asset-context.scss';

export class AssetContext {
  static inject() {
    return [DialogService];
  }

  @bindable contextChild;

  constructor(_DialogService) {
    this.dialogService = _DialogService;
    //
    this.uiService = uiService;
    this.assetsService = assetsService;
    this.provisionService = provisionService;
    //

    this.assetsList = null;
    this.asset = null;
    this.contextAction = null;

    this.contextHeaderViews = {
      streetView: 'streetView',
      cctv: 'cctv',
    }
    this.snapshot = false;
    this.deviceId = null;
    this.deviceUUId = null;

    this.loading = false;
    this.dtIndex = 0;
    this.trailRange = null;
    this.dateFilterDisabled = false;
    this.hasTrail = false;
    this.hasCCTV = false;
    this.allowTrail = false;
    this.activeContextHeaderView = this.contextHeaderViews.streetView;

    this.tripDetails = null;
    this.alertDetails = null;
    this.reminderDetails = null;
    this.DVRFeatures = null;
    this.allowCloseOnEsc = true;

    this.onHeaderAction = this.onHeaderAction.bind(this);
    this.onDateFilterChanged = this.onDateFilterChanged.bind(this);
    this.onChartTripDetails = this.onChartTripDetails.bind(this);
    this.onContextChildClose = this.onContextChildClose.bind(this);
    this.onBarContextActions = this.onBarContextActions.bind(this);

    this.onAlertClick = this.onAlertClick.bind(this);
    this.onReminderClick = this.onReminderClick.bind(this);

    this.onKeyDown = this.onKeyDown.bind(this);

    this.allowTrail = userService.hasPermission(
      'features.map.show-driver-address'
    );
  }

  activate(model) {
    if (!model) return;
    this.asset = model.item;
    this.contextAction = model.contextAction;
  }
  
  attached() {
    window.addEventListener('keydown', this.onKeyDown);
    this.getDeviceProperties();

    eventService.subscribe(
      EventsList.SignalrbroadcastSnapshot,
      this.onBroadcastSnapshot
    );

    eventService.subscribe(
      EventsList.MapContextNearby,
      this.onMapContextNearby
    );
  }

  detached() {
    window.removeEventListener('keydown', this.onKeyDown);

    eventService.unsubscribe(
      EventsList.SignalrbroadcastSnapshot,
      this.onBroadcastSnapshot
    );

    eventService.unsubscribe(
      EventsList.MapContextNearby,
      this.onMapContextNearby
    );
  }

  onKeyDown(e) {
    if (!this.allowCloseOnEsc || e.keyCode !== 27) return;

    if (this.tripDetails || this.alertDetails || this.reminderDetails) {
      this.onContextChildClose();
      return;
    }
  }

  @computedFrom('hasTrail')
  get headerIcon() {
    return this.hasTrail ? 'fi-crosshair' : 'fi-gesture';
  }

  @computedFrom('tripDetails', 'alertDetails', 'reminderDetails')
  get isChildActive() {
    return (this.tripDetails || this.alertDetails || this.reminderDetails) ? true : false;
  }

  @computedFrom('trailRange')
  get pastDates() {
    return this.trailRange.dtto < new Date();
  }

  getAsset = (id) => {
    return this.assetsService.getById(id).then(asset => {
      return asset
    })
  }

  checkForDevices = (deviceId) => {
    this.provisionService.getDevices(deviceId).then(([Device]) => {
      try {
        let features = Device.features.find(({ id, metadata }) => id === 'dvr' && metadata);
        if (features.metadata && features.metadata.channels) {
          this.DVRFeatures = features.metadata;
          this.hasCCTV = true;
        } else {
          this.hasCCTV = false;
        }
      } catch (e) {
        this.hasCCTV = false;
      }
    });
  }

  getDeviceProperties() {
    // CHECK IF CCTV FEATURE IS ENABLED
    this.snapshot = this.asset.snapshot ? this.asset.snapshot.isDeviceConnected : false;
    this.deviceId = this.asset.deviceId;
    this.deviceUUId = this.asset.deviceUUId;

    if (this.asset && this.asset.hasOwnProperty('links')) {
      this.asset.links.forEach(element => {
        if (element.sources.includes('video')) {
          this.getAsset(element.id).then(asset => {
            if (asset) {
              this.deviceId = asset.deviceId;
              this.deviceUUId = asset.deviceUUId;
              this.snapshot = asset.snapshot ? asset.snapshot.isDeviceConnected : false;
            }
            this.checkForDevices(this.deviceId);
          });
        }
      })
    } else {
      this.checkForDevices(this.deviceId);
    }
  }

  onBroadcastSnapshot = update => {
    //ignore if looking at a trail
    if (!update || this.hasTrail) return;

    if (update.id === this.asset.id) {
      this.asset.updateState(update);
    }
  };

  onMapContextNearby = () => {
    this.hasTrail = false;
    this.dateFilterDisabled = false;
  };

  onHeaderAction() {
    this.hasTrail ? this.hideTrail() : this.showTrail();
  }

  hideTrail() {
    this.contextAction(ContextActions.focus, {
      asset: this.asset
    }).then(() => {
      this.dateFilterDisabled = false;
      this.hasTrail = false;
    });
  }

  showTrail() {
    this.loading = true;
    this.dateFilterDisabled = true;

    let {
      dtfrom,
      dtto
    } = this.trailRange;
    this.contextAction(ContextActions.trail, {
      id: this.asset.id,
      dtfrom,
      dtto
    }).then(() => {
      this.loading = false;
      this.dateFilterDisabled = false;
      this.hasTrail = true;
    });
  }

  onDateFilterChanged(dtfrom, dtto, index) {
    this.trailRange = {
      dtfrom,
      dtto
    };
    this.dtIndex = index;

    if (this.hasTrail) {
      this.showTrail();
    }
  }

  onChartTripDetails(trip, trips) {
    if (trip) {
      let args = {
        dtfrom: trip.gpsStartLocal,
        dtto: trip.gpsEndLocal,
        id: this.asset.id
      };
      this.loading = true;
      this.contextAction(ContextActions.trip, args).then(telemetry => {
        let contextAction = this.contextAction || null;
        this.tripDetails = {
          trip,
          telemetry,
          trips,
          contextAction,
          hasCCTV: this.hasCCTV
        };
        this.contextChild = this.tripDetails;
        this.loading = false;
      });
    }
  }

  onContextChildClose() {
    if (this.hasTrail) {
      this.showTrail();
    } else {
      this.contextAction(ContextActions.focus, {
        asset: this.asset
      });
    }
    this.tripDetails = null;
    this.alertDetails = null;
    this.reminderDetails = null;
    this.contextChild = null;
  }

  onBarContextActions(e, action) {
    this.contextAction(ContextActions[action], {
      latlng: this.asset.latlng
    });
  }

  onAlertClick(alert, alerts) {
    let contextAction = this.contextAction || null;
    this.alertDetails = {
      alert,
      alerts,
      contextAction,
      hasCCTV: this.hasCCTV
    }
    this.contextChild = this.alertDetails;
    if (alert) {
      this.contextAction(ContextActions.alert, {
        alert
      });
    }
  }

  onReminderClick(reminder, reminders) {
    let contextAction = this.contextAction || null;
    this.reminderDetails = {
      reminder,
      reminders,
      contextAction
    }
    this.contextChild = this.reminderDetails;
    if (reminder) {
      this.contextAction(ContextActions.reminder, {
        reminder
      });
    }
  }

  openImmobilePrompt(enable) {
    this.allowCloseOnEsc = false;

    const message = enable ?
      'confirm_enable_immobilize' :
      'confirm_disable_immobilize';
    const title = 'confirm';

    this.dialogService
      .open({
        viewModel: PromptDialog,
        model: {
          title,
          message
        },
        lock: true
      })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.allowCloseOnEsc = true;
          this.immobilizer(enable);
        } else {
          return
        }
      })
  }

  immobilizer(enable) {
    //loading state?
    this.assetsService
      .immobilizer(this.asset.id, enable)
      .then(updatedState => {
        this.asset.computedState.immobilizer = updatedState;
      })
      .catch(() => {
        //show erro message?
      });
  }
}
