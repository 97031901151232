/** @format */
import {
  computedFrom
} from 'aurelia-framework';
import remindersService from 'services/api/remindersService';
import {
  ContextActions
} from '../../../map-context';
import {
  ConvertUtils
} from '@fonix/web-utils';

import './reminder-context-details.scss';

export class ReminderContextDetails {
  constructor() {
    this.remindersService = remindersService;
    this.loading = false;
    this.contextAction = null;

    this.accountUnit = ConvertUtils.getUnit('distance') || {
      name: 'km',
      weight: 0
    };
  }

  activate(model) {
    if (!model) return;
    this.reminder = model.reminder;
    this.reminders = model.reminders;
    this.contextAction = model.contextAction;
    this.loadReminderDetails();
  }

  loadReminderDetails() {
    this.loading = true;
    this.remindersService.getReminder(this.reminder.id).then(reminder => {
      if (reminder) {
        this.reminder = reminder
        this.reminder.comments = reminder.comments;
      }

      this.loading = false;
    });
  }

  onProcessClick() {
    this.loading = true;
    this.update({
      ...this.reminder,
      processed: true
    }).then(reminder => {
      this.reminder = reminder;
      this.loading = false;
    });
  }

  onConfirm() {
    this.update(this.reminder).then(reminder => {
      //server not returning comments array
      this.reminder.comments.push(this.reminder.comment);
      this.reminder.comment = null;
    });
  }

  update(reminder) {
    return this.remindersService.updateOccurrence(reminder);
  }

  @computedFrom('reminder')
  get latlng() {
    if (!this.reminder || !this.reminder.startAddress) return false;
    return [this.reminder.startAddress.location.latitude, this.reminder.startAddress.location.longitude];
  }

  @computedFrom('reminders')
  get remindersLength() {
    if (!this.reminders) return false;
    return this.reminders.length;
  }

  @computedFrom('reminders')
  get checkPreviousReminderState() {
    if (!this.reminders) return false;

    let currentReminderIndex = this.reminders.findIndex(reminder => reminder.id === this.reminder.id)
    return (currentReminderIndex > 0 && this.reminders[currentReminderIndex - 1]) ? true : false;
  }

  @computedFrom('reminders')
  get checkNextReminderState() {
    if (!this.reminders) return false;

    let currentReminderIndex = this.reminders.findIndex(reminder => reminder.id === this.reminder.id)
    return (currentReminderIndex >= 0 && this.reminders[currentReminderIndex + 1]) ? true : false;
  }

  previousReminder() {
    let currentReminderIndex = this.reminders.findIndex(reminder => reminder.id === this.reminder.id)
    let previousReminderIndex = (currentReminderIndex > 0 && this.reminders[currentReminderIndex - 1]) ? currentReminderIndex - 1 : 0;
    let reminder = this.reminders[previousReminderIndex];

    if (reminder && currentReminderIndex != previousReminderIndex) {
      this.changeReminder(reminder)
    }
  }

  nextReminder() {
    let currentReminderIndex = this.reminders.findIndex(reminder => reminder.id === this.reminder.id)
    let nextReminderIndex = (currentReminderIndex >= 0 && this.reminders[currentReminderIndex + 1]) ? currentReminderIndex + 1 : currentReminderIndex;
    let reminder = this.reminders[nextReminderIndex]

    if (reminder && currentReminderIndex != nextReminderIndex) {
      this.changeReminder(reminder)
    }
  }

  changeReminder(reminder) {
    this.loading = true;
    this.contextAction(ContextActions.reminder, {
      reminder
    }).then(result => {
      this.loading = false;
      this.reminder = reminder;
    });
  }
}
