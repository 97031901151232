/** @format */

import {
  bindable,
  computedFrom
} from 'aurelia-framework';
import telemetryService from 'services/api/telemetryService';

import {
  ConvertUtils
} from '@fonix/web-utils';
import './chart-trips.scss';

export class ChartTrips {
  @bindable asset;
  @bindable dateRange;

  @bindable onTripDetails;

  constructor() {
    this._telemetryService = telemetryService;

    this.speedUnit = null;
    this.dailyCharts = [];
    this.chartOptions = {
      seriesBarDistance: 5,
      axisY: {
        position: 'start',
        offset: 30,
        labelOffset: {
          x: -5
          // y: 5
        },
        showGrid: false
      },
      axisX: {
        offset: 13,
        labelOffset: {
          x: 0,
          y: 0
        },
        labelInterpolationFnc: (v, idx, arr) => {
          return idx < arr.length - 1 ? `${v.toFixed(1)}` : null;
        }
      },
      fullWidth: true,
      reverseData: false, //this is not reversing the labels, only the data
      horizontalBars: true
    };
    this.chartHeight = 150;

    this.tripsData = null;
    this.loading = true;
    this.sliderOptions = {
      enableMouseEvents: true
    };

    // this.selectedTrip = null;
    this.onSliderInit = this.onSliderInit.bind(this);
    this.onSliderSlide = this.onSliderSlide.bind(this);
    this.onChartPointClick = this.onChartPointClick.bind(this);
    this.onChartDraw = this.onChartDraw.bind(this);
  }

  attached() {
    let speedUnit = ConvertUtils.getUnit('distance');
    this.speedUnit = speedUnit ? speedUnit.name : 'km';
  }

  @computedFrom('tripsInfo')
  get totalTripsCount() {
    return this.tripsInfo ? this.tripsInfo.totalTrips : 0;
  }

  onSliderInit(instance) {
    this.slider = instance;
  }

  onSliderSlide(e) {
    if (e.detail) {
      let slide = e.detail.currentSlide;
      let trip = this.dailyCharts[slide];
      this.tripsInfo.slide = slide;
      this.tripsInfo.title = trip.title;
    }
  }

  moveSlider(e, action) {
    if (action === 'next') {
      this.slider.next();
    }
    if (action === 'prev') {
      this.slider.prev();
    }
  }

  onChartPointClick(e, meta) {
    let trip = this.findTrip(meta);
    let trips = this.tripsData;
    if (trip) {
      this.onTripDetails(trip, trips);
      // this.selectedTrip = trip;
    }
  }

  assetChanged(newAsset) {
    if (newAsset && this.dateRange) {
      this.loadData(this.dateRange);
    } else if (this.dailyCharts) {
      this.dailyCharts = null;
    }
  }

  dateRangeChanged(range) {
    if (range) {
      this.loadData(range);
    }
  }

  loadData(range) {
    if (this.asset && this.asset.deviceId) {
      let {
        dtto,
        dtfrom
      } = range || {};
      this._telemetryService
        .getJourneys(this.asset.id, dtfrom, dtto)
        .then(this.parseData.bind(this));
    }
  }

  parseData(data) {
    this.empty = true;

    this.dailyCharts = [];
    this.tripsData = data;

    //organize trips by day in a dictionary<day>
    let days = {};
    data.forEach(trip => {
      let day = days[trip.startYMD];

      if (!day) {
        days[trip.startYMD] = day = {
          trips: []
        };
      }

      day.title = trip.gpsDate;
      day.trips.push(trip);
    });

    //for each day, build chart data, and aggregate totals
    let totalTrips = 0;
    let dailyTotalTrips = 0;
    let totalDistance = 0;
    let totalTime = 0;

    let _labels = [];
    let _data = [];

    let makeChartData = trip => {
      _labels.push(trip.startHHMM);
      let mileage = ConvertUtils.convertUnit('distance', trip.mileage, false);
      _data.push({
        value: mileage.value,
        name: trip.startHHMM,
        meta: trip.id
      });
      totalDistance += trip.mileage; //in km
      totalTime += trip.durationMS;
    };

    for (let d in days) {
      _data = [];
      _labels = [];

      days[d].trips.reverse();
      days[d].trips.forEach(makeChartData);

      this.dailyCharts.push({
        title: days[d].title,
        data: {
          labels: _labels,
          series: [_data]
        },
        total: _data.length
      });
      totalTrips += _data.length;
      dailyTotalTrips = Math.max(_data.length, dailyTotalTrips);
    }

    //
    if (this.dailyCharts.length) {
      this.chartHeight = Math.max(dailyTotalTrips * 14, 150);
      this.tripsInfo = {
        title: this.dailyCharts[0].title,
        totalDays: this.dailyCharts.length,
        totalTrips: totalTrips,
        totalDistance: Math.round(totalDistance),
        totalTime,
        slide: 0
      };
      this.sliderOptions.enableMouseEvents = (this.dailyCharts.length > 1) ? true : false;

      if (this.slider) {
        this.slider.init();
      }
    } else {
      this.tripsInfo = null;
    }

    this.loading = false;
  }

  onChartDraw(data) {
    if (data.type === 'bar') {
      let trip = this.findTrip(data.meta);
      if (!trip || !trip.ecodrivingRating) return;

      data.element.addClass(trip.ecodrivingRating);
    }
  }

  findTrip(id) {
    return (this.tripsData || []).find(t => t.id == id);
  }
}
