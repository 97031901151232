/** @format */

import {
  bindable
} from 'aurelia-framework';
import remindersService, {
  ReminderStatus
} from 'services/api/remindersService';

import './asset-reminders.scss';

export class AssetReminders {
  @bindable asset;

  @bindable onReminderClick;

  constructor() {
    this.remindersService = remindersService;
    this.reminders = [];
    this.remindersCount = 0;
    this.showMax = 10;

    this.selectReminder = this.selectReminder.bind(this);
  }

  assetChanged(asset, old) {
    if (asset && old && asset.id === old.id) return;

    this.loadData();
  }

  loadData() {
    if (this.asset) {
      let filter = {
        assetId: this.asset.id
      }
      this.remindersService
        .getReminders(filter)
        .then(reminders => {
          this.remindersCount = reminders.length;
          this.reminders = reminders.slice(0, this.showMax);
        });
    }
  }

  selectReminder(reminder) {
    if (this.onReminderClick) {
      this.onReminderClick(reminder, this.reminders);
    }
  }
}
