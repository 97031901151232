import {
  bindable,
  observable
} from 'aurelia-framework';
import {
  AssetNameFormatter
} from 'components/value-converters/asset-formatted-name-vc';
import {
  FilterListValueConverter
} from 'components/value-converters/filters/filter-list-vc'
import {
  AssetStates,
  AssetTypes
} from 'services/api/assetsService';
import geoService from 'services/api/geoService';
import uiService from 'services/uiService';

import './track-search.scss';

const ASSET_ICONS = {
  [AssetTypes.vehicle]: 'fi-car',
  [AssetTypes.mobile]: 'fi-smartphone'
};

const getIcon = asset => {
  let travel = asset.status === AssetStates.travelling;
  let panic = asset.panic;

  return panic ?
    'fi-alert-circle' :
    travel ? 'fi-navigation_arrow' : ASSET_ICONS[asset.assetType];
};

export class TrackSearch {
  inputElement;
  @bindable searchValue;
  @bindable onSearchValueClear;
  @bindable listAssets;
  @bindable listPois;
  @bindable listHistory;
  @bindable onItemSelected;
  @bindable onAddressSelected;

  @observable searchInputFocused;

  constructor() {
    this.geoService = geoService;
    this.uiService = uiService;

    this.listOfAssets = [];
    this.filteredAssetsList = [];
    this.filteredPOIList = [];
    this.combinedList = [];
    this.addresses = [];
    this.searchHistory = [];

    this.loading = false;
    this.searchInputFocused = false;
    this.showAddressAction = false;
    this.showAddressList = false;

    this.allowCloseOnEsc = true;

    this.onSearchClear = this.onSearchClear.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.onAddressClick = this.onAddressClick.bind(this);
    this.onSearchElementInteraction = this.onSearchElementInteraction.bind(this);

    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onSearchClear() {
    this.searchInputFocused = false;
    if (this.inputElement) {
      this.inputElement.blur();
    }

    this.searchValue = '';
    if (this.onSearchValueClear) {
      this.onSearchValueClear();
    }
  }

  attached() {
    window.addEventListener('keydown', this.onKeyDown);
  }

  detached() {
    window.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown(e) {
    if (this.showAddressAction && e.keyCode == 13) {
      this.toggleSearchAddresses();
    } 
    
    if (!this.allowCloseOnEsc || e.keyCode !== 27) return;
    this.onSearchClear();
  }
  
  cleanSearch() {
    this.onSearchClear();
  }

  onItemClick(item, event) {
    if (item) {
      let action = event.target.dataset.action;

      if (!action) this.combinedList.forEach(a => (a.selected = a.id === item.id));

      this.searchValue = null;
      if (typeof this.onItemSelected === 'function') {
        if (item._name == 'asset') {
          this.onItemSelected(item.asset, action);
        } else if (item._name == 'poi') {
          this.onItemSelected(item, action);
        }
      }
    }
  }

  toggleSearchAddresses(e) {
    this.showAddressList = !this.showAddressList;
    this.showAddressListChanged();
  }

  showAddressListChanged() {
    if (this.showAddressList) {
      this.loading = true;
      this.geoService
        .geoCoding(this.searchValue)
        .then(addresses => {
          this.addresses = addresses;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    } else {
      this.addresses = [];
    }
  }

  onAddressClick(address) {
    if (this.onAddressSelected) {
      this.onAddressSelected(address);
    }
  }

  listAssetsChanged() {
    this.listOfAssets = this.listAssets.map(this.assetToList);
    this.listHistoryChanged();
  }

  listPoisChanged() {
    this.listHistoryChanged();
  }

  searchValueChanged() {
    this.addresses = [];
    this.showAddressList = false;
    this.showAddressAction = (this.searchValue && this.searchValue.length >= 3) ? true : false;

    if (!this.searchValue || this.searchValue.length < 2) {
      this.combinedList = []
      return;
    };

    if (this.listOfAssets && this.listOfAssets.length) {
      this.filteredAssetsList = FilterListValueConverter.filter(this.listOfAssets, 'name,groupName,code,formattedAddress', this.searchValue)
    } else {
      this.filteredAssetsList = [];
    }
    if (this.listPois && this.listPois.length) {
      this.filteredPOIList = FilterListValueConverter.filter(this.listPois, 'name,groupName,code,formattedAddress', this.searchValue)
    } else {
      this.filteredPOIList = [];
    }

    let list = [];
    if (this.filteredAssetsList) {
      list = list.concat(this.filteredAssetsList);
    }
    if (this.filteredPOIList) {
      list = list.concat(this.filteredPOIList);
    }
    this.combinedList = list;
  }

  get showList() {
    return (!this.searchValue || this.searchValue.length < 2) ? false : true;
  }

  assetToList(asset) {
    let showArrow = asset.status === AssetStates.travelling;
    let {
      id,
      name,
      shortAddress,
      groupName,
      groupId
    } = asset;

    let formattedName = AssetNameFormatter.formatName(asset) || name;

    return {
      asset: asset, //original class
      _name: asset._name,
      id,
      name: name,
      formattedName,
      groupName,
      groupId,
      shortAddress,
      //
      selected: false,
      icon: getIcon(asset),
      iconRotation: showArrow ? asset.heading : 0
    };
  }

  onSearchElementInteraction(event) {
    setTimeout(() => {
      this.searchInputFocused = event.detail.status
    }, 250);
  }

  listHistoryChanged() {
    if (this.listHistory && this.listHistory.length) {
      let list = [];
      if (this.listOfAssets && this.listOfAssets.length) {
        list = list.concat(this.listOfAssets);
      }
      if (this.listPois && this.listPois.length) {
        list = list.concat(this.listPois);
      }
      if (list && list.length) {
        this.searchHistory = this.listHistory.filter((elem, index, self) => {
          let item = list.find(item => item.id === elem.id && (elem.type === item._name));
          let value = self.findIndex(obj => {
            return obj.id === elem.id && obj.type === elem.type;
          })
          if (index === value && item) {
            item.weight = self.filter(obj => {
              return obj.id === elem.id && obj.type === elem.type;
            }).length;
            elem.item = item;

            return index === value;
          }
        }).map(e => e.item);
      }
    } else {
      this.searchHistory = [];
    }
  }
}
